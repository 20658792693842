import { useCallback } from "react";

import { useBeforeUnload, useLocation } from "@remix-run/react";

/**
 * Return the state in the current location.
 * @param clear  - by default we clear on beforeunload event
 *                to prevent hydration mismatch when full page refresh is happening
 * @returns
 */
export const useLocationState = <T>(
  clear = true,
): Readonly<[T | undefined, (params: T) => void]> => {
  const location = useLocation();
  useBeforeUnload(
    useCallback(() => {
      if (clear) {
        window.history.replaceState(undefined, "");
      }
    }, [clear]),
  );
  const setState = useCallback((state: T) => {
    window.history.replaceState(state, "");
  }, []);

  return [location.state as T, setState] as const;
};
